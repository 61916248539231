// BREAKINGBAR
@import "core";

@include respond-to(base-nomediaquery) {
  @keyframes breaking2items{
    5%,50%{transform:translateY(0%);}
    55%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking3items{
    3.33%,33%{transform:translateY(0);}
    36.67%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking4items{
    2.5%,25%{transform:translateY(0);}
    27.5%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking5items{
    2%,20%{transform:translateY(0);}
    22%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking6items{
    1.67%,16.67%{transform:translateY(0);}
    18.34%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking7items{
    1.43%,14.29%{transform:translateY(0);}
    15.72%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking8items{
    1.25%,12.5%{transform:translateY(0);}
    13.75%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking9items{
    1.11%,11.1%{transform:translateY(0);}
    12.21%,100%{transform:translateY(-150%);}
  }
  @keyframes breaking10items{
    1%,10%{transform:translateY(0);}
    11%,100%{transform:translateY(-150%);}
  }
}

@include respond-to(base-inline) {
  @media (scripting:none) {
    .tc_breakingbar{display:none!important;}
  }
}

@include respond-to(base) {
  .tc_breakingbar{display:flex;flex-direction:column;-webkit-user-select:none;user-select:none;position:fixed;bottom:0;left:0;right:0;z-index:11;}
  .tc_breakingbar__heading{display:flex;align-items:center;background-color:var(--color-dark-special);height:var(--spacing-40);padding:0 var(--spacing-8-step);}
  .tc_breakingbar__list{position:relative;list-style:none;height:var(--spacing-48);overflow:hidden;background-color:var(--color-breaking);}
  .tc_breakingbar__item{display:flex;align-items:flex-start;position:absolute;top:0;left:0;right:0;bottom:0;height:var(--spacing-40);padding:0 var(--spacing-8-step);margin:var(--spacing-4) 0;overflow:hidden;
    .tc_heading{display:block;overflow:hidden;text-overflow:ellipsis;color:var(--color-midnight);}
  }
  .tc_breakingbar__button{position:absolute;top:0;right:0;padding:var(--spacing-4);
    .tc_button{color:var(--color-white);}
  }
  .tc_breakingbar__list{
    &:has(> :nth-child(10)) .tc_breakingbar__item{animation:100s infinite breaking10items;}
    &:has(> :only-child) .tc_breakingbar__item{animation:none;}
    &:has(> :nth-child(2):last-child) .tc_breakingbar__item{animation:20s infinite breaking2items;}
    &:has(> :nth-child(3):last-child) .tc_breakingbar__item{animation:30s infinite breaking3items;}
    &:has(> :nth-child(4):last-child) .tc_breakingbar__item{animation:40s infinite breaking4items;}
    &:has(> :nth-child(5):last-child) .tc_breakingbar__item{animation:50s infinite breaking5items;}
    &:has(> :nth-child(6):last-child) .tc_breakingbar__item{animation:60s infinite breaking6items;}
    &:has(> :nth-child(7):last-child) .tc_breakingbar__item{animation:70s infinite breaking7items;}
    &:has(> :nth-child(8):last-child) .tc_breakingbar__item{animation:80s infinite breaking8items;}
    &:has(> :nth-child(9):last-child) .tc_breakingbar__item{animation:90s infinite breaking9items;}

    &:has(> :nth-child(2)){
      .tc_breakingbar__item{will-change:transform;transform:translateY(150%);
        &:nth-child(2){animation-delay:10s;}
        &:nth-child(3){animation-delay:20s;}
        &:nth-child(4){animation-delay:30s;}
        &:nth-child(5){animation-delay:40s;}
        &:nth-child(6){animation-delay:50s;}
        &:nth-child(7){animation-delay:60s;}
        &:nth-child(8){animation-delay:70s;}
        &:nth-child(9){animation-delay:80s;}
        &:nth-child(10){animation-delay:90s;}
        &:nth-child(n+11){display:none;}
      }
    }
  }
  @supports (-webkit-line-clamp:2){ // add multi-line ellipsis
    .tc_breakingbar__item .tc_heading{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
  }
  @media (prefers-reduced-motion:reduce) {
    .tc_breakingbar__item:first-child{transform:translateY(0)!important;}
  }
}

@include respond-to(medium) {
  .tc_breakingbar{flex-direction:row;}
  .tc_breakingbar__heading{height:auto;}
  .tc_breakingbar__list{flex:1 1 auto;height:auto;}
  .tc_breakingbar__item{align-items:center;height:auto;margin:0;white-space:nowrap;}
  .tc_breakingbar__button{position:static;
    .tc_button{color:var(--color-midnight);}
  }
  @supports (-webkit-line-clamp:2){ // cancel out multi-line ellipsis
    .tc_breakingbar__item .tc_heading{display:block}
  }
}

@include respond-to(print) {
  .tc_breakingbar{display:none;}
}